import { useCallback, useEffect, useState } from 'react';
import { ZERO_ADDRESS, addPoint } from '../lib/util';
import Modal  from './Modal'
import { useConnect, useAccount, useDisconnect} from "@starknet-react/core";
import { useAccount as useAccountEVM } from 'wagmi'
import { get } from '../http';

export default ({starkAddress, onSuccess}) => {
    const { connect, connectors } = useConnect();
    const { disconnect } = useDisconnect();
    const { account, address, status } = useAccount();
    const {address:address_evm} = useAccountEVM()
    const [showWallet, setShowWallet] = useState(false)
    const queryParams = new URLSearchParams(window.location.search);
    const paramValue = queryParams.get('ref');
    const [isWhite, setIsWhite] = useState(false)

    const toBind = useCallback(async()=>{
        if(address) {
            let data = await get('/api/user/bind', {
                stark_address: address.length == 65 ? address.replace('0x','0x0'):address,
                address: address_evm,
                referrer: paramValue || ''
            })
            onSuccess()
        }
    }, [address, address_evm, paramValue])

    useEffect(()=>{
        (async () => {
            if(address || starkAddress) {
                let {data} = await get('/api/user/isWhite', {
                    stark_address: (starkAddress || address).length == 65 ? (starkAddress || address).replace('0x','0x0'):(starkAddress || address)
                })
                setIsWhite(data)
            } else {
                setIsWhite(false)
            }
        })()
        
    }, [address || starkAddress])
    return (
        <div className='w100'>
            {
               starkAddress ?<div className="w100 ta cf fz-14 cy m-t-15 pointer">Starknet address : <span className='underline'>{addPoint(starkAddress)}</span>(<span className='fz-12 cf'>{isWhite?'In whitelist':' Not in whitelist'}</span>)</div>:( status == 'connected' ? <div className="w100 ta cf fz-14 cy  m-t-15 underline" >{addPoint(address)}(<span className='fz-12 cf'>{isWhite?'In whitelist':' Not in whitelist'}</span>)<span className='underline pointer cf m-l-10' onClick={toBind}>Bind</span>  <span className='underline pointer cf m-l-10' onClick={() => disconnect()}>Disconnect</span> </div>:
                <div className="w100 ta cf fz-14 cy underline m-t-15 pointer" onClick={_=>setShowWallet(true)}>
                Bind starknet address to freemint 
            </div>)
            }
            
            <Modal title="Connect Starknet Wallet " onClose={_=>setShowWallet(false)} isOpen={showWallet && !address}>
      {connectors.map((connector) => (
        <div className="connect flex flex-center pointer" onClick={() => connect({ connector })}>
{
                <img src={connector.icon.light} alt="" className="w-25"/>
            }
            {connector.name}
        </div>
      ))}
            </Modal>
        </div>
    )
}