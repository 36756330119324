import Web3 from "web3"
import MintManager from "./MintManager"
import ERC404 from "./ERC404"
import contradeAddress from './address'
import BN from "bignumber.js"
import { chainId, provider_url_by_chain_id } from "../lib/const"

import { useSendTransaction } from 'wagmi'

export const web3_provider = new Web3.providers.HttpProvider(provider_url_by_chain_id[chainId])
export const getBalanceETH = (user) => {
  const web3 = new Web3(web3_provider)
  return web3.eth.getBalance(user)
}

export const minted = () => {
    const web3 = new Web3(web3_provider)
    return new web3.eth.Contract(MintManager, contradeAddress.MintManager).methods.minted().call()
}
export const freeMintable = (user) => {
  const web3 = new Web3(web3_provider)
  return new web3.eth.Contract(MintManager, contradeAddress.MintManager).methods.freeMintable(user).call()
}
export const price = () => {
  const web3 = new Web3(web3_provider)
  return new web3.eth.Contract(MintManager, contradeAddress.MintManager).methods.price().call()
}
export const userMinted = (user) => {
  const web3 = new Web3(web3_provider)
  return new web3.eth.Contract(MintManager, contradeAddress.MintManager).methods.userMinted(user).call()
}

export const queryMintETHValue = (num) => {
  const web3 = new Web3(web3_provider)
  return new web3.eth.Contract(MintManager, contradeAddress.MintManager).methods.queryMintETHValue(num).call()
}