import axios from "axios"
import { base_url } from "../lib/const";

import CryptoJS from "crypto-js";

const securityKey = "Begger-Security-2024-02-20"

export const  cryptoData =(path, data)=> {
  let params = {
      t: Date.now(),
      // chain_id: store.getState().chainId,
      ...data
  }
  let keys = [...Object.keys(params)]
  keys.sort();
  let queryArray = []
  for (let key of keys) {
      queryArray.push(`${key}=${params[key]}`)
  }
  let raw = queryArray.join("&")
  let sign = CryptoJS.MD5(raw + path + securityKey)
  let url = `?${raw}&sign=${sign}`
  return url
}
axios.interceptors.response.use(
  response => {
    // console.log(response)
    return response
  },
  error => {
    if(error.response && error.response.status == 401) {
      // store.dispatch(setToken(''))
      // showLogin()
    }
    return Promise.reject(error);
  }
)

export const baseUrl = base_url
export function get(
  url,
  data
) {
  return new Promise((res, rej) => {
    url+=cryptoData(url, data||{})
    axios({
      method: 'get',
      url: url.slice(0, 4) === 'http' ? url : (baseUrl + url),
       headers: {
          'authorization': ''
       }
   }).then(result => {
     console.log(result)
    if (!('code' in result.data)) {
      res(result.data)
    } else if(Number(result.data.code) == 1 || Number(result.data.code) == 7) {
      res(result.data)
    } else {
     rej(result.data)
    }
 }).catch(err => rej(err))
  })
}
export function post(
  url,
  data
) {
  return new Promise((res, rej) => {
    axios({
      method: 'post',
      url: baseUrl + url,
      data,
      transformRequest: [
         function (data) {
            let ret = ''
            for (let it in data) {
               ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            ret = ret.substring(0, ret.lastIndexOf('&'));
            return ret
         }
       ],
       headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'authorization': ''
       }
   }).then(result => {
     console.log(result)
     console.log(Number(result.code) == 1)
    if(Number(result.data.code) == 1) {
      res(result.data)
    } else {
      rej(result.data)
     }
 }).catch(err => rej(err))
  })
}