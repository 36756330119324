


export const isMain = false // is main or not
export const chainId = isMain ? 1:11155111
export const total = 500000
export const provider_url_by_chain_id = {
    '1': 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    '11155111':'https://rpc.sepolia.org',
    '5': 'https://rpc.ankr.com/eth_goerli',
    '11155420': 'https://sepolia.optimism.io'
}



export const base_url = isMain ? "https://api.begger.one":"https://abi.begger.one"