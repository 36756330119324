import { Skeleton } from "antd"
import { total } from "../lib/const"
import { numFormat, toFixed } from "../lib/util"

export default ({minted, loading}) => {
    
    return (
        <div className="progress">
            <div className="flex flex-between">
                <span className="fwb fz-14">Progress</span>
                <span className="fz-14 c0">{loading?<Skeleton.Button size='small'/>:(minted||0)}/<span className="c03">{numFormat(total) }</span></span>
            </div>
            <div className="progress-bar m-t-10">
                <div className="progress-inner" style={{width: (toFixed((minted||0)*100/total, 2))+'%' }}></div>
            </div>
        </div>
    )
}