import Header from "../components/Header"
import slogan from '../assets/images/slogan.svg'
import slogan2 from '../assets/images/slogan2.svg'
import banner from '../assets/images/banner.svg'
import bannermini from '../assets/images/bannermini.svg'
import nft from '../assets/images/nft.svg'
import mint from '../assets/images/mint.svg'
import mintmini from '../assets/images/mintmini.svg'
import main from '../assets/images/main.svg'
import rule from '../assets/images/rule.svg'
import rulemini from '../assets/images/rulemini.svg'
import footer from '../assets/images/footer.svg'
import twitter_b from '../assets/images/twitter_b.svg'
import telegram_b from '../assets/images/telegram_b.svg'
import swap from '../assets/images/swap.svg'
import nft1 from '../assets/images/nft/nft1.png'
import nft2 from '../assets/images/nft/nft2.png'
import nft3 from '../assets/images/nft/nft3.png'
import nft4 from '../assets/images/nft/nft4.png'
import './index.scss'
import Mint from "../components/Mint"
import { message } from "antd"
import { useEffect, useState } from "react"
import { minted, price } from "../contract/method"
import { useAccount } from "wagmi"
import { onCopyToText } from "../lib/util"
import mintrule from "../lib/mintrule"
import Modal from "../components/Modal"
import { smarttoken } from "../lib/smarttoken"

export default () => {

    const {address} = useAccount()
    const [showmore, setShowmore] = useState(false)

    const queryParams = new URLSearchParams(window.location.search);

    useEffect(()=>{
        if(queryParams) {
            document.getElementById('point-mint').click()
        }
    }, [queryParams])
   
    return (
        <div className="w100">
            <Header/>
            <div className="p-20 m-t-40 m-m-t-1">
              {/* <img src={slogan} alt="" className="slogan" /> */}
              <div className="slogan">
              <span className="color g-bold">Crypto Begger</span> is a programmable token using TokenScrip technology on ERC-5169 standard token
              <span onClick={_=>setShowmore(true)} className="cg underline pointer fz-14 p-l-10">learn more. <br/> </span>
            
             <span className="fz-18"> Opening up a new future for NFT semi-fungible tokens based on ERC20 native liquidity. <br/>At the same time, Beggar is also a meme coin, paying tribute to friends who work hard to find Airdrop opportunities on the chain.</span>
              
              </div>
              
              <div className="cf6 fz-15 slogan-text ta m-auto p-t-30 p-b-15">
              

            </div>
            <a className="btn view-btn bgg c0 m-auto pointer normal-a" id="point-mint" href="#mint">Mint Beggar</a>
            </div>
            
            <div className="mint-box flex flex-middle flex-column m-auto">

            <img src={banner} alt="" className="show-p" />
            <img src={bannermini} alt="" className="show-m m-auto"/>

            </div>
            
            <div className="mint-box flex flex-center m-auto flex-between m-flex-column w100 p-20" id="mint">
                <img src={nft} alt="" className="m-w-320" />
                <div className="flex flex-column flex-start main-box">
                    <div className="cg fz-15 p-b-35">Beggar NFT</div>
                    <img src={mint} alt=""  />
                    <div className="cf6 fz-16 tl p-t-35 p-b-35">
                    Crypto Beggar is composed of 100,000 cryptopunk-turned-beggar meme NFTs, of which the Mint part is 50K (the former 5K Free mint). The other 50K waits for the Mint amount to reach 10K to join the Uniswap V3 liquidity pool, and can be traded directly on Uniswap.
<br/>
<br/>
When there is a price difference between Uniswap's price and Mint NFT's, traders can arbitrage between Mint NFT and Uniswap Dex.

                    </div>
                    {/* <img src={main} alt="" className="show-p" />
                    <img src={mintmini} alt="" className="show-m w100" /> */}
                    <Mint/>
                    
                    
                </div>
            </div>
            <div className="mint-box flex flex-middle flex-column m-auto p-t-60">
                <div className="mint-rule bgf p-b-30 bdr-64 p-l-20 p-r-20">
                    <div className="mint-rule-title cf g-bold fz-28">MINT RULE</div>
                    <div className="flex flex-wrap m-t-30 m-flex-column ">
                    {
                        mintrule.map((item, index) => {
                            return <div className="mint-rule-item flex-1 p-l-15 p-r-15 m-p-t-15 m-p-b-15">
                                <div className="fz-28 fwb g-bold">{item.title}</div>
                                <div className="ta fz-15 m-t-16">{item.content}</div>
                            </div>
                        })
                    }
                    </div>
                    

                </div>
                <div className="flex flex-center nft flex-wrap flex-between m-t-80 m-flex-middle gap-10">
                    <img src={nft1} alt="" />
                    <img src={nft2} alt="" />
                    <img src={nft3} alt="" />
                    <img src={nft4} alt="" />
                </div>
                <div className="fz-48 color g-bold slogan2 m-t-50">
                    Use Gas to create trust, a brave man who overcomes obstacles
                </div>
            </div>
            
            <div className="footer m-t-80 p-t-50 p-b-50">
            <img src={footer} alt="" className="m-auto" />
            <div className="flex flex-center flex-middle gap-20 fz-16 m-t-20">
                <a className="flex flex-center gap-5 normal-a c0" href="https://twitter.com/beggar_erc" target="_blank">
                     <img src={twitter_b} alt="" />
                     Twitter
                </a>
                <a className="flex flex-center gap-5 normal-a c0" href="/" target="_blank">
                     <img src={telegram_b} alt="" />
                     Telegram
                </a>
                <a className="flex flex-center gap-5 normal-a c0" href="/" target="_blank">
                     <img src={swap} alt="" />
                     Swap
                </a>
            </div>
            </div>

            <Modal title="Smart Token" isOpen={showmore} onClose={_=>setShowmore(false)}>
                <div className="fz-14 p-t-20 learn-more">
                    {
                        smarttoken
                    }
                </div>

            </Modal>
            


        </div>
    )
}