
import BigNumber from "bignumber.js";
import web3 from 'web3';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const fromUnit = (wei, decimal = 18) => {
    if (wei <= 0) {
      return 0
    }
    let weiwei = Number(toFixed(wei, 0)) || 0
    return (web3.utils.fromWei((numberToStr(weiwei) || 0).toString(), decimal === 18 ? "ether" : "mwei"));
  };
  const numberToStr = (num = 0) => {
    let splits = num.toString().toLowerCase().split("e+");
    let result = splits[0];
    if (splits.length === 2) {
      result = result * Math.pow(10, parseInt(splits[1]));
    }
    return result.toLocaleString("fullwide", {
      useGrouping: false,
    });
  };
  
  const howManyZero = (num) => {
    if (num > 1) {
      return 0
    } else {
      let zeronum = 0
      for (let i = 0; i <= 18; i++) {
        if (Number(num) >= Number(Math.pow(10, 0 - i))) {
          zeronum = i
          break;
        }
      }
      return zeronum - 1
    }
  }
  export const toFixed = (amount, num) => {
    if (Number(amount) < 1) {
      num <= howManyZero(amount) && (num = howManyZero(amount) + num)
    }
    return new BigNumber(
      Math.floor(Number(amount) * Math.pow(10, num)) / Math.pow(10, num)
    ).toString(10);
  };
export const addPoint = (address, len = 5) => {
    return address ? address.substr(0, len) + '...' + address.substr(address.length - len,) : ''
}
export const numFormat = function (num) {
    num = num.toString().split(".");  // 分隔小数点
    var arr = num[0].split("").reverse();  // 转换成字符数组并且倒序排列
    var res = [];
    for (var i = 0, len = arr.length; i < len; i++) {
        if (i % 3 === 0 && i !== 0) {
            res.push(",");   // 添加分隔符
        }
        res.push(arr[i]);
    }
    res.reverse(); // 再次倒序成为正确的顺序

    if (num[1]) {  // 如果有小数的话添加小数部分
        return res.join("").concat("." + num[1]);
    } else {
        return res.join("");
    }
}
export const onCopyToText = (text) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    // message.success(t('Copied'))
  };
  export const formatNumber = (n) => {
    n = n.toString();
    return n[1] ? n : "0" + n;
  };
  export const formatTime = (timestamp) => {
    if(!timestamp) {
      return '-'
    }
    let date = new Date(Number(timestamp) * 1000);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();
    return (
      [year, month, day].map(formatNumber).join("-") +
      " " +
      [hour, minute, second].map(formatNumber).join(":")
    );
  };
  export const formatYearDate = (timestamp) => {
    let date = new Date(Number(timestamp) * 1000);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return (
      [year, month, day].map(formatNumber).join("-")
    );
  };
  export const formatDate = (timestamp) => {
    let date = new Date(Number(timestamp) * 1000);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return (
      [month, day].map(formatNumber).join("-")
    );
  };


export const testInput = (value, max) => {
  return (!/^[0-9]*[.,]?[0-9]*$/.test(value) || value.indexOf('+') >=0 || value.indexOf('e') >=0 || value.indexOf('-') >=0) && (max ? value <=max:true)
}
export const toWei = (amount) => {
  try {
    return toFixed(amount*1e18, 0)
  } catch {
    return 0
  }
};