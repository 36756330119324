import logo from '../assets/images/logo.svg'
import twitter from '../assets/images/twitter.svg'
import telegarm from '../assets/images/telegarm.svg'
import menu from '../assets/images/menu.svg'
import './index.scss'
import Connect from './Connect'
import ConnectStarknet from './ConnectStarknet'
export default () => {
    return (
        <div className="header flex flex-between flex-center p-t-16">
            {/* <div className='show-m menu-offset'></div> */}
            <img src={logo} alt="" className='logo' />
            {/* <img src={menu} alt="" className='show-m' /> */}
            <div className='flex flex-center gap-70 show-p'>
            <a href="https://twitter.com/beggar_erc" target="_blank" className='cf normal-a flex gap-7'>
            <img src={twitter} alt="" />
            Twitter
            </a>
            <a href="/" className='cf normal-a flex gap-7'>
            <img src={telegarm} alt="" />
            Telegarm
            </a>
            </div>

            <div className="flex flex-center gap-10 show-p">
                 <div className="view-btn cg">Swap now!</div>
                 <Connect/>
                 {/* <div className="swap-btn bgg c0">Connect Wallet</div> */}
            </div>
            <div className='show-m'><Connect/></div>
        </div>
    )
}