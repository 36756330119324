import Progress from './Progress'
import './index.scss'
import arrow from '../assets/images/arrow.svg'
import x from '../assets/images/x.svg'
import eth from '../assets/images/eth.svg'
import { useCallback, useEffect, useState } from 'react'
import { freeMintable, minted, price, queryMintETHValue, userMinted, web3_provider } from '../contract/method'
import { ZERO_ADDRESS, addPoint, fromUnit, onCopyToText, testInput, toFixed } from '../lib/util'
import { Button, Skeleton, Tooltip, message } from 'antd'
import {get} from '../http/index'
import contradeAddress from '../contract/address'
import { useAccount } from 'wagmi'
import { getNetwork, sendTransaction, waitForTransaction } from '@wagmi/core'
import { chainId } from '../lib/const'
import Web3 from 'web3'
import MintManager from '../contract/MintManager'
import ConnectStarknet from './ConnectStarknet'
import { isAddress } from 'viem'
export default () => {
    const [num, setNum] = useState(1)
    const [mintedNum, setMintedNum] = useState(0)
    const [mintPrice, setMintPrice] = useState(0)
    const [loading, setLoading] = useState(false)
    const [mintloading, setMintLoading] = useState(false)
    const [priceloading, setPriceLoading] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [starkAddress, setStarkAddress] = useState('')
    const [refer, setRefer] = useState('')
    const [referer, setReferer] = useState('')
    const [refresh, setRefresh] = useState(0)
    const [refreshLoad, setRefreshLoad] = useState(0)
    const queryParams = new URLSearchParams(window.location.search);
    const paramValue = queryParams.get('ref');
    const {address} = useAccount()

    const toBroad = useCallback(() => {
        let text_1 = "Crypto begger @beggar_erc is a perfect combination of programmable protocol based on ERC5159 and NFT.%0aBegger NFT is on Mint."
        let url_text = '%0a'+encodeURIComponent(window.location.origin + '?ref='+address+'#mint')
        let text_last = '%0aJoin me in the search for freedom in the hash of passwords.'
        let text = text_1+url_text+text_last
        window.open('https://twitter.com/intent/tweet?text='+text)
    }, [address])
    
    const toBind = useCallback(async() => {
        if(!address) {
            message.warning('Please connect wallet')
          return
          }
        
          const {chain} = getNetwork()

          if(chain.id*1 != chainId) {
            message.warning("Wrong network")
          return
          }
          setMintLoading(true)
          try {

            let user_minted = await userMinted(address)
            if(user_minted >=10 || user_minted*1+num*1 > 10) {
                message.error("Maximum number of Mint per address is 10")
                setMintLoading(false)
                return
            }
            console.log(user_minted)
            let {data: {referrer, signature}} = await get('/api/user/mintParams', {
                contract: contradeAddress.MintManager,
                address: address
            })
            console.log(referrer, signature)


            if(signature) {
                let can_free_mint = await freeMintable(address)
                console.log(can_free_mint)
                const web3 = new Web3(web3_provider)
                console.log(num,isAddress(referrer)?referrer:ZERO_ADDRESS)
                const transaction = new web3.eth.Contract(MintManager, contradeAddress.MintManager).methods.mint2(num,isAddress(referrer)?referrer:ZERO_ADDRESS, signature)
                let value = mintPrice*(user_minted?num:num-1)
                const result = await sendTransaction({
                    to: contradeAddress.MintManager,
                    value: value,
                    data: transaction.encodeABI(),
                    // gasPrice: parseGwei('58')
                    // maxPriorityFeePerGas: parseGwei('58')
                })
                // console.log(result)
                let  data = await waitForTransaction({hash: result.hash})
                setMintLoading(false)
                setRefreshLoad(refreshLoad+1)
                if (data.status == 'success') {
                    message.success("Mint successful")
                } else {
                    message.error("Mint failed")
                }
            } else {
                const web3 = new Web3(web3_provider)
                console.log(num,isAddress(refer)?refer:ZERO_ADDRESS)
                const transaction = new web3.eth.Contract(MintManager, contradeAddress.MintManager).methods.mint1(num,isAddress(refer)?refer:ZERO_ADDRESS)
                let value = mintPrice*num
                const result = await sendTransaction({
                    to: contradeAddress.MintManager,
                    value: value,
                    data: transaction.encodeABI(),
                    // gasPrice: parseGwei('58')
                    // maxPriorityFeePerGas: parseGwei('58')
                })
                // console.log(result)
                let  data = await waitForTransaction({hash: result.hash})
                setMintLoading(false)
                setRefreshLoad(refreshLoad+1)
                if (data.status == 'success') {
                    message.success("Mint successful")
                } else {
                    message.error("Mint failed")
                }
            }
          } catch (err) {
            console.log(err)
          }

    }, [address, num, mintPrice, refreshLoad]) 
    useEffect(()=>{
        (async() => {
            // setPriceLoading(true)
            // let total_price = await queryMintETHValue(num)
            // console.log(total_price)
            // setTotalPrice(total_price)
            // setPriceLoading(false)
        })()
    }, [num])
    useEffect(()=>{
        (async ()=>{
            setLoading(true)
            let minted_num = await minted()
            let mint_price = await price()
            setMintedNum(minted_num)
            setMintPrice(mint_price)
            setLoading(false)
        })()
        
    }, [refreshLoad])

    useEffect(()=>{
        (async ()=>{
            setLoading(true)
            try {
                if(address) {
                    let {data: {stark_address, referrer}} = await get('/api/user/info', {
                        address
                    })
                    setStarkAddress(stark_address)
                    setReferer(referrer)
                    setLoading(false)
                }
            }catch (err) {
                console.log(err)
                setLoading(false)
            }
            
            
        })()
        
    }, [address, refresh])


    useEffect(()=>{
        setRefer((paramValue||'').replace(/[\s+,\',\",\‘,\’,\“,\”,\<,\>,\《,\》,\\, \/, \?]/g, ''))
    }, [paramValue])
    return (
        <>
        <div className="mint-wrapper bgf p-t-16 p-r-30 p-b-16 p-l-30 bdr-24">
             <Progress minted={mintedNum} loading={loading}/>
             <div className="flex flex-wrap m-t-20">
                 <div className="flex flex-column flex-start">
                    <span className='fwb fz-14'>Amount</span>
                    <div className="flex flex-center m-t-10">
                        <input min={1} max={10} className='fz-20 fwb w-80 tl p-l-3 input-number' value={num} onChange={(e) => {
                            if (testInput(e.target.value)) {
                                return
                              }
                              if(e.target.value === '0') {
                                return
                              }
                              if(e.target.value>10) {
                                return
                              }
                              setNum(e.target.value);
                        }} type="number"/>
                        {/* <div className="flex flex-column flex-middle">
                            <img src={arrow} alt=""  className={' m-l-1 pointer '+(num>=10 ?'op03 disabled':'')} onClick={_=>{
                                num < 10 && setNum(num+1)
                            }}/>
                            <img src={arrow} alt="" className={' rotate-180  pointer '+(num<=1 ?'op03 disabled':'')} onClick={_=>{
                                num > 1 && setNum(num-1)
                            }}/>
                        </div> */}
                    </div>
                 </div>

                 <div className="flex flex-column flex-1 flex-start m-l-30">
                    <span className='fwb fz-14'>Price</span>
                    <div className="flex flex-center m-t-10">
                        <div className='fz-20 fwb w-40 tl p-l-3 flex flex-center gap-5'>
                            <img src={eth} alt="" />
                            <div className='wb flex flex-center'>
                                {
                                    loading ? <Skeleton.Button size='small'/>:toFixed(fromUnit(mintPrice), 2)
                                }
                                ETH</div>
                        </div>
                    </div>
                 </div>
                 {
                    refer || referer ?  <div className="flex flex-column flex-1 flex-end">
                    <span className='fwb fz-14 tr'>Inviter</span>
                    <div className='fz-14  p-l-3 flex tr cy m-t-10'>{addPoint(refer || referer)}</div>
                 </div>:''
                 }

                

             </div>
        </div>
        {
            
            address ?<ConnectStarknet starkAddress={starkAddress} onSuccess={()=>setRefresh(refresh+1)}/> :''
        }
        {
            address ? <div className='w100 flex flex-center gap-10 flex-middle m-t-25'><Button loading={mintloading ||loading} className="bgg c0 fz-16 w100 mint-btn  btn pointer flex flex-center flex-middle flex-1" onClick={toBind}> 
             <span>Mint</span>
            </Button>
            
            
            </div>:
            <div className="bgg c0 fz-16 w100 mint-btn m-t-35 btn pointer flex flex-center flex-middle" onClick={()=>{
                document.getElementById('connect').click()
            }}>
                Connect Wallet
            </div>
        }

{
                        address ? <div className="flex flex-center m-t-10 w100 gap-10 ">
                            <Tooltip title="Invite friends to Mint to get 10% commission reward (ETH), click to generate the invitation link.">
                            <div className="btn invite-btn flex-1 pointer cg" onClick={()=>{
                            onCopyToText((window.location.host.includes('localhost')?'':'https://')+window.location.host+'?ref='+address)
                         message.success('The invitation link has been copied, please send it to a friend')
                     }}>Invite friend</div>
                            </Tooltip>
                            <Tooltip title="Share Crypto Begger Mint to X (Twitter).Invite friends to Mint to get 10% commission reward (ETH)">
                            <div onClick={toBroad} className="btn post-btn flex-1 flex-center flex flex-middle gap-5 pointer cf">Post <img src={x} alt="" /></div>
                            </Tooltip>
                        
                    </div>:''
                    }
        
        </>
        
    )
}