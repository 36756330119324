export default [
    {
        title: 'A',
        content: <span>Total amount of Mint:<b>50K</b></span>
    },
    {
        title: 'B',
        content: <span>Airdrop whitelist address 5K Freemint on StarkNet (STRK airdrop whitelist address: each address can <b>only have 1 Mint</b>)
        </span>
    },
    {
        title: 'C',
        content: <span>The remaining 45,000 pieces need to be paid with ETH (0.02 ETH/piece), and the maximum number of Mint per address is 10</span>
    },
    {
        title: 'D',
        content: <span>Mint Fund Allocation <br/>
        50% enters the Uniswap liquidity pool<br/>
        20% Liquidity Management<br/>
        10% referral commission reward<br/>
        (If there is no inviter, it will be transferred to liquidity management)<br/>
        20% Team Marketing
        </span>
    }
]