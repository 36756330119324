import logo from './logo.svg';
import { Routes, Route, Link } from "react-router-dom";
import router from './router';
import './App.css';
import { sepolia, mainnet } from "@starknet-react/chains";
import {
  StarknetConfig,
  publicProvider,
  argent,
  braavos,
  useInjectedConnectors,
  voyager
} from "@starknet-react/core";

function App() {
  // const { connectors } = useInjectedConnectors({
  //   // Show these connectors if the user has no connector installed.
  //   recommended: [
  //     argent(),
  //     braavos(),
  //   ],
  //   // Hide recommended connectors if the user has any connector installed.
  //   // includeRecommended: "always",
  //   // Randomize the order of the connectors.
  //   order: "random"
  // });

  const connectors = [
    argent(),
    braavos(),
  ]
  return (
    <StarknetConfig
      chains={[mainnet, sepolia]}
      provider={publicProvider()}
      connectors={connectors}
      explorer={voyager}
    >


<div className="App">
      
      <Routes>
            {
              router.map(item => {
                return (
                  <Route key={item.path} path={item.path} exact={true} element={item.component} />
                )
              })
            }
          </Routes>
    </div>
    </StarknetConfig>
  );
}

export default App;
