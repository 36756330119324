import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/scss/common.scss'
import reportWebVitals from './reportWebVitals';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  sepolia,
  arbitrumGoerli,
  optimismSepolia,
  goerli
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
const isMain = false

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    isMain ? mainnet : sepolia
  ],
  [publicProvider()]
);
const { connectors } = getDefaultWallets({
  appName: 'begger',
  projectId: '29c1e7b612aa4db1c3add059290ffe8a',
  chains,
});
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

